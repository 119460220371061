/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

export class Constants {
  static readonly DATE_FMT = 'dd/MMM/yyyy';
  static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
  static readonly DATE_FMT_INPUT = 'yyyy-MM-dd';
  static readonly DATE_TIME_FMT_INPUT = `${Constants.DATE_FMT_INPUT} hh:mm:ss`;
}
