/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {GlobalErrorHandingService} from "@/app/shared/ErrorHandler/GlobalErrorHanding.service";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { AppUser } from '../../shared/model/appuser';
import { environment } from '../../../environments/environment';

import { PasswordDto } from '../model/PasswordDto';
import { GuestAppointment } from '../model/guestAppointment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

const CREATE_APPUSER_URI: string = environment.API_URL + '/appuser/create';
const UPDATE_APPUSER_URI: string = environment.API_URL + '/appuser/update';
const GET_APPUSERS_URI: string = environment.API_URL + '/appuser/appusers';
const GET_APPUSERS_BY_USERPROFILE_URI: string = environment.API_URL + '/appuser/findAppUserListByProfile';
const GET_FORGOTPASSWORD_URI: string = environment.API_URL + '/registration/user/forget-password';
const POST_UPDATEPASSWORD_URI: string = environment.API_URL + '/registration/user/forget_password_update';
const POST_ADMINUPDATEPASSWORD_URI: string = environment.API_URL + '/registration/user/adminPasswordReset';

const SIGNUP_EMAIL_CONFIRMATION_URI: string = environment.API_URL + '/registration/user/signup-email-confirmation';
const RESEND_REGISTRATION_TOKE_URI: string = environment.API_URL + '/registration/user/resendRegistrationToken';


const GET_APPUSER_BY_USERNAME_URI: string = environment.API_URL + '/appuser';
const CREATE_SIGNUP_ME: string = environment.API_URL + '/registration/signup-me';
const CREATE_SIGNUP_BY_ADMIN: string = environment.API_URL + '/registration/signupByAdmin';


const CREATE_GUEST_APPOINTMENT: string = environment.API_URL + '/registration/guest-appointment';
const GET_GUEST_APPOINTMENT_LIST: string = environment.API_URL + '/registration/guestAppointments';
const UPDATE_GUEST_APPOINTMENT: string = environment.API_URL + '/registration/updateGuestAppointmentStatus';



const GET_VALIDATE_UNIQUE_USERNAME: string = environment.API_URL + '/registration/user/validate-unique-user';
const GET_VALIDATE_UNIQUE_USEREMAIL: string = environment.API_URL + '/registration/user/validate-unique-email';
const GET_APPUSER_BY_USEREMAIL: string = environment.API_URL + '/registration/user/findAppUserByEmail';
const GET_LINK_PERSON_TO_USER: string = environment.API_URL + '/appuser/linkperson';

@Injectable()
export class AppUserService {

  appUser = new AppUser;
  createAppUserMode = true;
  appUserAlerts: Array<any> = [];
  private formModeSubject = new BehaviorSubject<string>('CRT');
  formModeCast = this.formModeSubject.asObservable();
  private appUserSubject = new BehaviorSubject<AppUser>(this.appUser);
  appUserCast = this.appUserSubject.asObservable();

  constructor(private http: HttpClient, private errorService: GlobalErrorHandingService) {
  }


  changeAppUserFormMode(mode) {
    console.log(mode);
    this.formModeSubject.next(mode);
  }

  // update call form by call list data
  setAppUser(appUserRecord: AppUser) {
    console.log('AppUserService.setAppUser');
    this.createAppUserMode = false;
    this.appUserSubject.next(appUserRecord);
  }

  // update AppUser form by AppUser list data
  editAppUser(appUserRecord: AppUser) {
    this.createAppUserMode = false;
    console.log('AppUserService.editAppUser appUserRecord ' + appUserRecord);
    this.appUserSubject.next(appUserRecord);
  }


  // Create App User
  createAppUsers(appuser: AppUser): Observable<AppUser> {
    const url = CREATE_APPUSER_URI;
    return this.http.post<AppUser>(url, appuser, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', new AppUser())) // then handle the error
      );
  }

  //         ******* Create New account SignUp Page********
  enroll(argAppUser: AppUser): Observable<AppUser> {
    console.log(argAppUser);
    const url = CREATE_SIGNUP_ME;
    return this.http.post<AppUser>(url, argAppUser, httpOptions)
      // tslint:disable-next-line:align
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new AppUser())) // then handle the error
      );
  }

  //         ******* Guest Appointment********
  guestAppointment(argGuestAppointment: GuestAppointment): Observable<GuestAppointment> {
    console.log(argGuestAppointment);
    const url = CREATE_GUEST_APPOINTMENT;
    return this.http.post<GuestAppointment>(url, argGuestAppointment, httpOptions)
      // tslint:disable-next-line:align
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new GuestAppointment())) // then handle the error
      );
  }

  guestAppointmentList() {
    return this.http.get<GuestAppointment[]>(GET_GUEST_APPOINTMENT_LIST)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  // Change Call status of App user /callId/callStatus/appUserId.
  changeGuestAppointmentStatus(argGuestAppointmentId: number, argCallStatus: string): Observable<GuestAppointment> {
    const url = UPDATE_GUEST_APPOINTMENT + `/${argGuestAppointmentId}/${argCallStatus}`;
    return this.http.post<GuestAppointment>(url, httpOptions)
      .pipe(
        tap(_ => console.log(`Guest Appointment Status change`)),
        catchError(this.errorService.handleError('changeGuestAppointmentStatus', null))
      );
  }



  //         ******* Create New account SignUp Page********
  signupByAdmin(argAppUser: AppUser): Observable<AppUser> {
    console.log(argAppUser);
    const url = CREATE_SIGNUP_BY_ADMIN;
    return this.http.post<AppUser>(url, argAppUser, httpOptions)
      // tslint:disable-next-line:align
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new AppUser())) // then handle the error
      );
  }

  //         ******* Create New account SignUp Page********
  updateProfileByAdmin(argAppUser: AppUser): Observable<AppUser> {
    const url = UPDATE_APPUSER_URI + `/${argAppUser.appUserId}`;
    return this.http.post<AppUser>(url, argAppUser, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', new AppUser())) // then handle the error
      );
  }


  //         ******* Send Verfication Email ********

  // tslint:disable-next-line:ban-types
  forgotPassword(email: String) {
    console.log(email);
    const url = GET_FORGOTPASSWORD_URI + '?email=' + `${email}`;
    return this.http.get<any>(url)
      .pipe(
      /*  retry(1), // retry a failed request up to 3 times*/
        catchError(this.errorService.handleError('Not Found Type Error', email)) // then handle the error
      );
  }

  //         ******* Update Password ********

  updatePassword(password: string, confirmPassword: string, token: string) {
    // console.log(password + '//' + confirmPassword + '//' + token);
    // tslint:disable-next-line:max-line-length
    const url2 = POST_UPDATEPASSWORD_URI + '?newPassword=' + `${password}` + '&oldPassword=' + `${confirmPassword}` + '&token=' + `${token}`;
    return this.http.post<any>(url2, httpOptions)
      // tslint:disable-next-line:align
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', '')) // then handle the error
      );
  }

  //         ******* Admin Update Password ********

  adminResetPassword(argAasswordDto: PasswordDto, appUserId: number) {
    // console.log(password + '//' + confirmPassword + '//' + token);
    // tslint:disable-next-line:max-line-length
    const url = POST_ADMINUPDATEPASSWORD_URI + '?appUserId=' + `${appUserId}`;
    return this.http.post<any>(url, argAasswordDto, httpOptions)
      // tslint:disable-next-line:align
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', '')) // then handle the error
      );
  }


  validateUniqueUserName(argUserName: any) {
    console.log(argUserName);
    const url = GET_VALIDATE_UNIQUE_USERNAME + `/${argUserName}`;
    return this.http.get<any>(url, httpOptions);
  }


  validateUniqueEmail(argEmail: any) {
    console.log(argEmail);
    const url = GET_VALIDATE_UNIQUE_USEREMAIL + '?email=' + argEmail;
    return this.http.get<any>(url, httpOptions);
  }

  // Get App User Details by email, this will return false if no data found otherwise AppUser Object
  findAppUserByEmail(argUserEmail: string) {
    const url = GET_APPUSER_BY_USEREMAIL + '?email=' + argUserEmail;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => {
          console.log(`findAppUserByEmail Method`);
        }),
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  // Update App User
  updateAppUser(appUserEdit: AppUser): Observable<AppUser> {
    const url = UPDATE_APPUSER_URI + `/${appUserEdit.appUserId}`;
    return this.http.post<AppUser>(url, appUserEdit, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', new AppUser())) // then handle the error
      );
  }


  // Create App User
  linkPersonToUser(argAppUserId: number, argPersonId: number): Observable<AppUser> {
    const url = GET_LINK_PERSON_TO_USER + `/${argAppUserId}` + `/${argPersonId}`;
    return this.http.post<AppUser>(url, argAppUserId, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', new AppUser())) // then handle the error
      );
  }


  // Get All App Users List
  getAllAppUsersList() {
    return this.http.get<AppUser[]>(GET_APPUSERS_URI)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }


  // Get All App Users List
  getAppUserListByProfile(argStatus: string, argUserProfile: string) {
    const url = GET_APPUSERS_BY_USERPROFILE_URI + `/${argStatus}/${argUserProfile}`;
    return this.http.get<AppUser[]>(url)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  // Get All App Users List
  getAppUserByUserName(userName: string) {
    const url = GET_APPUSER_BY_USERNAME_URI + `/${userName}`;
    return this.http.get<AppUser>(url)
      .pipe(
        catchError(this.errorService.handleError('getAppUserByUserName', new AppUser())) // then handle the error
      );
  }

  // ****Alert message Array push and close methods** - Started**/
  public alertArrayPush(type, message) {
    this.appUserAlerts.push(
      {
        type: type,
        message: message
      }
    );
  }

  signupEmailConfirmation(token: string) {
    const url = SIGNUP_EMAIL_CONFIRMATION_URI + '?token=' + `${token}`;
    return this.http.get<any>(url)
      .pipe(
        catchError(this.errorService.handleError('signupEmailConfirmation', '')) // then handle the error
      );
  }

  resendRegistrationToken(token: string) {
    const url = RESEND_REGISTRATION_TOKE_URI + '?token=' + `${token}`;
    return this.http.get<any>(url)
      .pipe(
        catchError(this.errorService.handleError('signupEmailConfirmation', '')) // then handle the error
      );
  }


  public closeAlert(alert: any) {
    const index: number = this.appUserAlerts.indexOf(alert);
    this.appUserAlerts.splice(index, 1);
  }

}
