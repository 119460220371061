/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import { CallDetails } from './../model/calldetails';
import { Injectable } from '@angular/core';
import { AddressDetails, AppUser, MetaData } from '../model';
import { BehaviorSubject, of } from 'rxjs';
import { Person } from '../model/person';
import { DocumentDetails } from '../model/DocumentDetails';
import { CallsMaster } from '../model/CallsMaster';

/**
 * Shared Singleton contains re-usable data for the application
 */
@Injectable()
export class SharedDataService {

  callsMaster: CallsMaster = new CallsMaster();
  callDetails: CallDetails = new CallDetails()
  private metaDataList: MetaData[] =[];
  private personDocList: DocumentDetails[] =[];
  private addressDetailsList: AddressDetails[] =[];
  private doctorsList :AppUser[] =[];
  private loggedInUser: AppUser = new AppUser();
  private smsStatus: string;
  docDashBoardCallsMasterList: CallsMaster = new CallsMaster()
  selectedPatientDtls: Person = new Person();
  private sharedComponentMode: boolean;


  private callMasterSubject = new BehaviorSubject<CallsMaster>(this.callsMaster);
  callMasterCast = this.callMasterSubject.asObservable();


  private callDtlSubject = new BehaviorSubject<CallDetails>(this.callDetails);
  callDtlCast = this.callDtlSubject.asObservable();

  private metaDataListSubject = new BehaviorSubject<MetaData[]>(this.metaDataList);
  metaDataListCast = this.metaDataListSubject.asObservable();

  private personDocListSubject = new BehaviorSubject<DocumentDetails[]>(this.personDocList);
  personDocListCast = this.personDocListSubject.asObservable();

  private personPtListSubject = new BehaviorSubject<any>([]);
  personPtListCast = this.personPtListSubject.asObservable();

  private addressDetailsListSubject = new BehaviorSubject<AddressDetails[]>(this.addressDetailsList);
  addressDetailsListCast = this.addressDetailsListSubject.asObservable();

  private doctorsListSubject = new BehaviorSubject<AppUser[]>( this.doctorsList);
  doctorsListCast = this.doctorsListSubject.asObservable();

  private loggedInUserSubject = new BehaviorSubject<AppUser>(this.loggedInUser);
  loggedInUserCast = this.loggedInUserSubject.asObservable();

  private allParentAndDependentsSubject = new BehaviorSubject<Person>(new Person());
  allParentAndDependentsCast = this.allParentAndDependentsSubject.asObservable();

  private smsStatusSubject = new BehaviorSubject<string>('Failure');
  smsStatusCast = this.smsStatusSubject.asObservable();

  private sharedComponentModeSubject = new BehaviorSubject<boolean>(false);
  sharedComponentModeCast = this.sharedComponentModeSubject.asObservable();

  private sharedPersonComponentModeSubject = new BehaviorSubject<boolean>(false);
  sharedPersonComponentModeCast = this.sharedPersonComponentModeSubject.asObservable();

  private person = new Person();

  private personSubject = new BehaviorSubject<Person>(this.person);
  personCast = this.personSubject.asObservable();
  private formModeSubject = new BehaviorSubject<string>('CRT');
  formModeCast = this.formModeSubject.asObservable();


  private loggedInPersonSubject = new BehaviorSubject<Person>(this.person);
  loggedInPersonCast = this.loggedInPersonSubject.asObservable();

  setCallsMasterNext(callsMaster: CallsMaster) {
    this.callMasterSubject.next(callsMaster);
  }

  setCallDetailsNext(callDetails: CallDetails) {
    this.callDtlSubject.next(callDetails);
  }

  setMetaDataListNext(metaData: MetaData[]) {
    this.metaDataListSubject.next(metaData);
  }

  setCallerDetailsDataNext(addressDetailsList: AddressDetails[]) {
    this.addressDetailsListSubject.next(addressDetailsList);
  }

  setDoctorsListNext(doctorsList: AppUser[]) {
    this.doctorsListSubject.next(doctorsList);
  }

  setLoggedInUserNext(loggedInUser: AppUser) {
    this.loggedInUserSubject.next(loggedInUser);
  }

  setAllParentAndDependentsNext(allParentAndDependents: Person) {
    this.allParentAndDependentsSubject.next(allParentAndDependents);
  }

  setSmsStatusNext(smsStatus: string) {
    this.smsStatusSubject.next(smsStatus);
  }

  setSharedComponentModeNext(sharedComponentMode: boolean) {
    this.sharedComponentModeSubject.next(sharedComponentMode);
  }

  setSharedPersonComponentModeNext(sharedPersonComponentMode: boolean) {
    this.sharedPersonComponentModeSubject.next(sharedPersonComponentMode);
  }

  setPerson(personRecord: Person) {
    this.personSubject.next(personRecord);
  }

  setLoggedInPerson(personRecord: Person) {
    this.loggedInPersonSubject.next(personRecord);
  }

  changePersonFormMode(mode) {
    this.formModeSubject.next(mode);
  }

  getCallsMaster(): CallsMaster {
    return this.callsMaster;
  }

  getCallDetails(): CallDetails {
    return this.callDetails;
  }

  getMetaDataList(): MetaData[] {
    return this.metaDataList;
  }

  getPersonDocList(): DocumentDetails[] {
    return this.personDocList;
  }

  setCallerDetailsData(addressDetailsList: AddressDetails[]) {
    this.addressDetailsList = addressDetailsList;
  }

  getCallerDetailsData(): AddressDetails[] {
    return this.addressDetailsList;
  }

  setDoctorsList(doctorsList: AppUser[]) {
    this.doctorsList = doctorsList;
  }

  getDoctorsList(): AppUser[] {
    return this.doctorsList;
  }

  setLoggedInUser(loggedInUser: AppUser) {
    this.loggedInUser = loggedInUser;
  }
  setdocDashBoardCallsMasterList(callsMasterList: CallsMaster) {
    this.docDashBoardCallsMasterList = callsMasterList;
  }
  getdocDashBoardCallsMasterList() {
    return this.docDashBoardCallsMasterList;
  }
  setSelectedPatientDtls(personRecord: Person) {
    this.selectedPatientDtls = personRecord;
  }
  getSelectedPatientDtls() {
    return this.selectedPatientDtls;
  }
  getLoggedInUser(): AppUser {
    return this.loggedInUser;
  }

  getStationNameNameById(callerAddressId: number, addressDetailsList: AddressDetails[]) {
    for (const addressDetails of addressDetailsList) {
      console.log('Inside the loop : ' + addressDetails.companyName);
      if (callerAddressId === addressDetails.addressId) {
        return addressDetails.companyName;
      }
    }
    return null;
  }

  getDoctorNameNameById(docId: number, appUserDataList: AppUser[]) {
    for (const appUser of appUserDataList) {
      console.log('Inside the loop : ' + appUser.appUserId);
      if (docId === appUser.appUserId) {
        // this.toMobilePhone = appUser.phoneMobile;
        return appUser.userName;
      }
    }
    return null;
  }

  getDoctorMobileNumberById(docId: number, appUserDataList: AppUser[]) {
    for (const appUser of appUserDataList) {
      console.log('Inside the loop : ' + appUser.appUserId);
      if (docId === appUser.appUserId) {
        return appUser.phoneMobile;
      }
    }
    return null;
  }


  addZeroInTime(hhmm) {
    if (hhmm < 10) {
      hhmm = '0' + hhmm;
    }
    return hhmm;
  }

  isEmpty(obj) {
    // tslint:disable-next-line: forin
    for (const key in obj) {
      console.log('isEmpty:' + key);
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

}
