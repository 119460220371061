/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

export class AppUser {
  public appUserId: number;
  public userName: string;  // will be null in most of cases
  public userPassword: string;
  public passwordConfirm: string;
  public phoneMobile: string;
  public phoneTrad: string;
  public userEmail: string;
// metadata
  public userProfile: string;
// metadata
  public active: string;
  public expiryDate: Date;
  public addressId: number;
  public personId: number;

// these are not part of data persistance
  // metadata
  public title: string;
  public firstName: string;
  public lastName: string;
  // metadata
  public gender: string;
  public dateOfBirth: Date;
  public organisation: string;
  public createdBy: number;
  public createdDate: Date;
  public lastModifiedBy: number;
  public lastModifiedDate: Date;
  public loggedInUserProfileImg: any;

}
