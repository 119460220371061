/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropDownFilterPipe} from './dropdown-filter.pipe';
import {FilterPipe} from './filter.pipe';
import {AddressDetailsFilterPipe} from './addressdetail-filter.pipe';
import {DateTimeFormatPipe} from './DateTimeFormatPipe';
import {DateFormatPipe} from './DateFormatPipe';
import {DateTimeFormatPipeInput} from './DateTimeFormatPipeInput';
import {DateFormatPipeInput} from './DateFormatPipeInput';
import {DisableControlDirective} from '../directives/disable-control-directive';
import {UniqueEmailValidatorDirective} from '../directives/unique-email-validator.directive';
import {UniqueUsernameValidatorDirective} from '../directives/unique-username-validator.directive';
import {DocAppDialogComponent} from '../modules/doc-app-dialog/doc-app-dialog.component';
import {SafeHtmlPipe} from './SafeHtmlPipe';

@NgModule(
  {
    imports: [CommonModule],
    declarations: [
      DropDownFilterPipe,
      FilterPipe,
      AddressDetailsFilterPipe,
      DateFormatPipe,
      DateTimeFormatPipe,
      DateTimeFormatPipeInput,
      DateFormatPipeInput,
      DisableControlDirective,
      UniqueEmailValidatorDirective,
      UniqueUsernameValidatorDirective,
      SafeHtmlPipe
    ],
    providers: [
      DropDownFilterPipe,
      FilterPipe,
      AddressDetailsFilterPipe,
      DateFormatPipe,
      DateTimeFormatPipe,
      DateTimeFormatPipeInput,
      DateFormatPipeInput,
      DisableControlDirective,
      UniqueEmailValidatorDirective,
      UniqueUsernameValidatorDirective,
      SafeHtmlPipe
    ],
    exports: [DropDownFilterPipe,
      FilterPipe,
      AddressDetailsFilterPipe,
      DateFormatPipe,
      DateTimeFormatPipe,
      DateTimeFormatPipeInput,
      DateFormatPipeInput,
      DisableControlDirective,
      UniqueEmailValidatorDirective,
      UniqueUsernameValidatorDirective,
      SafeHtmlPipe
    ]
  }
)
export class SharedPipesModule {


}
