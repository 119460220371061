/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, retry, map} from 'rxjs/operators';
import {Person} from '../model/person';
import {environment} from '../../../environments/environment';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {DocumentSet} from '../model/DocumentSet';
import {GlobalErrorHandingService} from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};
const CREATE_PERSON_URI: string = environment.API_URL + '/person/create'; // URI for person create API
const UPDATE_PERSON_URI: string = environment.API_URL + '/person/update'; // URI for person update API
const GET_PERSONS_URI: string = environment.API_URL + '/person/persons'; // URI for get All persons API
const GET_FINDBYFIRSTNAME_URI: string = environment.API_URL + '/person/findByFirstName'; // URI for person by firstName
const GET_FINDBYPERSONID_URI: string = environment.API_URL + '/person/find'; // URI for person by person Id
const GET_FINDPERSON_BY_PERSON_ID_URI: string = environment.API_URL + '/person/finPersonByPersonId'; // URI for person by person Id
const GET_SPECIFIC_PERSON_BY_PERSONID_URI: string = environment.API_URL + '/person/findSpecificDataByPersonId'; // URI for person details by person Id
// URI for person and all dependents by person Id
const GET_FINDALLBY_PARENT_AND_DEPENDENT_URI: string = environment.API_URL + '/person/findAllParentAndDependents';
const CREATE_DEPENDENT_PERSON_URI: string = environment.API_URL + '/person/createDependent'; // URI for person create Dependent API


@Injectable()
export class PersonService {

  private person: Person = new Person();
  private DocumentSet: DocumentSet;

  private personSubject = new BehaviorSubject<Person>(this.person);
  personCast = this.personSubject.asObservable();

  private formModeSubject = new BehaviorSubject<string>('CRT');
  formModeCast = this.formModeSubject.asObservable();

  constructor(private http: HttpClient,  private errorService: GlobalErrorHandingService ) {
  }

  // Create Person
  createPerson(person: Person): Observable<Person> {
    const url = CREATE_PERSON_URI;
    return this.http.post<Person>(url, person, httpOptions)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }

  // Create Person
  createDependentPerson(person: Person, personId : number): Observable<Person> {

    // Convert empty strings to empty arrays
    Object.keys(person).forEach(key => {
      if (person[key] === '' && Array.isArray(person[key])) {
        person[key] = [];
      }
    });
    const url = CREATE_DEPENDENT_PERSON_URI + `/${personId}`;
    return this.http.post<Person>(url, person, httpOptions)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }





  // Update Person
  updatePerson(personEdit: Person): Observable<Person> {
    const url = UPDATE_PERSON_URI + `/${personEdit.personId}`;
    return this.http.post<Person>(url, personEdit, httpOptions)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }

  setPersonPt(personRecord: Person) {
    console.log('Received in personPt Service :' + personRecord);
    this.personSubject.next(personRecord);
  }

  changePersonPtFormMode(mode) {
    this.formModeSubject.next(mode);
  }


  // Get All Person List
  getAllPersonsList() {
    return this.http.get<Person[]>(GET_PERSONS_URI)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  // Get person by firstName
  getPersonByFirstName(firstName) {
    const url = GET_FINDBYFIRSTNAME_URI + `/${firstName}`;
    return this.http.get<Person>(url)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }

  // Get person by personId
  getPersonById(personId: number): Observable<Person>  {
    const url = GET_FINDBYPERSONID_URI + `/${personId}`;
    return this.http.get<Person>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }


  // Get person by personId
  getPersonByPersonId(argsPersonId: number): Observable<Person>  {
    const url = GET_FINDPERSON_BY_PERSON_ID_URI + `/${argsPersonId}`;
    return this.http.get<Person>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }

  // Get only parent and Chils by personId
  findAllParentAndDependent(personId: number): Observable<Person>  {
    const url = GET_FINDALLBY_PARENT_AND_DEPENDENT_URI + `/${personId}`;
    return this.http.get<Person>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }
  findSpecificDataByPersonId(personId: number): Observable<Person> {
      const url = GET_SPECIFIC_PERSON_BY_PERSONID_URI + `/${personId}`;
    return this.http.get<Person>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', new Person())) // then handle the error
      );
  }

}
