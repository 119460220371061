/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import { Pipe, PipeTransform } from '@angular/core';
import {MetaData} from "../model/metadata";
import {AddressDetails} from "../model";

@Pipe({ name: 'matchesAddressId' })

export class AddressDetailsFilterPipe implements PipeTransform
{
  addressDetails: AddressDetails;
  transform(addressDetailsList: Array<AddressDetails>, addressId: number):
  Array<AddressDetails> {
    console.log("address Id: " + addressId);
    return addressDetailsList.filter(addressDetails => addressDetails.addressId === addressId); } }
