/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {DocHubStripePaymentService} from "@/app/shared/services/docHubStripePayment.service";
import {VideoCallService} from "@/app/shared/websocket/VideoCallService";
import {MatDialogRef} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {BrowserModule} from "@angular/platform-browser";
import {NgxSignaturePadModule} from "@eve-sama/ngx-signature-pad";
import {NgxMatTimepickerModule} from "ngx-mat-timepicker";
import {authGuardInterceptor} from "src/app/shared/guard/authGuard.interceptor";
import {WindowRefService} from "src/app/shared/utils/WindowRefService";
import { PersonService } from './shared/services/person.service';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ErrorHandlerSnakBar } from 'src/app/shared/ErrorHandler/ErrorHandlerSnakBar';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, AuthService } from './shared';
import {SharedDataService, AppUserService, AddressDetailsService, DataService, MetaDataService} from './shared/services';
import { DateFormat } from './shared/modules/date-format/date-format';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ConfirmDialogComponent } from './shared/modules/confirm-dialog/confirm-dialog.component';
import { MaterialCommonModule } from './shared/material/material.common.module';
import { InfoDialogComponent } from './shared/modules/info-dialog/info-dialog.component';
import { InformationDialogComponent } from './shared/modules/information-dialog/information-dialog.component';
import { VideoDialogComponent } from './shared/modules/video-dialog/video-dialog.component';
import { WebSocketService } from './shared/websocket/WebSocketService';
import { VideoCallMessageDialogComponent } from './shared/modules/Video-call-message-dialog/Video-call-message-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GlobalErrorHandingService } from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';
import { ActivityLogsService } from './shared/services/activityLogs.service';
import {ProfileImgService} from './shared/services/ProfileImg.service';
import { SeeDrNowService } from './shared/services/seeDrNow.service';
import {CallsMasterService} from './shared/services/CallsMaster.service';
import { ProfileDialogComponent } from './shared/modules/profile-dialog/profile-dialog.component';
import { PaymentDialogComponent } from './shared/modules/payment-dialog/payment-dialog.component';

const lang = 'en-US';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  // for development
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    exports: [
        NgxMaskModule
    ],
    imports: [
      BrowserModule,
        BrowserAnimationsModule,
        PdfViewerModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxMaskModule.forRoot(),
        MaterialCommonModule,
        MatIconModule,
       NgxMatTimepickerModule.setLocale(lang),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        NgbModule,
        NgxSignaturePadModule,

    ],
    declarations: [AppComponent, ConfirmDialogComponent, InfoDialogComponent,
        InformationDialogComponent, VideoDialogComponent, VideoCallMessageDialogComponent, ProfileDialogComponent, PaymentDialogComponent,
      ],
    providers: [AuthGuard, AuthService, SharedDataService, PersonService, AppUserService, ActivityLogsService,
        ErrorHandlerSnakBar, WebSocketService, VideoCallService, GlobalErrorHandingService, ProfileImgService, SeeDrNowService,
        DatePipe, MetaDataService, AddressDetailsService, DataService, CallsMasterService,WindowRefService,DocHubStripePaymentService,
        { provide: HTTP_INTERCEPTORS, useClass: authGuardInterceptor, multi: true },
        /*{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestHeaderInterceptor, multi: true },*/
        { provide: DateAdapter, useClass: DateFormat },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MatDialogRef, useValue: undefined },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {

}
