/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { DocumentSet } from '../model/DocumentSet';
import { PersonSignature } from '../model/PersonSignature';
import {GlobalErrorHandingService} from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';
import {Person} from '../model/person';
import {ProfileImg} from '../model/ProfileImg';
import {CouponsDetails} from '../model/CouponsDetails';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};
const UPLOAD_PROFILE_IMG_URI: string = environment.API_URL + '/profileImg/upload';
const GET_PROFILE_IMG_URI: string = environment.API_URL + '/profileImg/get';

@Injectable()
export class ProfileImgService {

  message = 'Image uploaded successfully';
  constructor(private http: HttpClient,  private errorService: GlobalErrorHandingService ) {
  }


  onUpload(argFile: File, argPersonId: number) {
    console.log(argFile);
    // FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const formdata: FormData = new FormData();
    formdata.append('file', argFile, 'BingoName');
    const url = UPLOAD_PROFILE_IMG_URI + '?person_id=' + argPersonId;

    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('"Content-Type' ,'multipart/form-data');



    // Make a call to the Spring Boot Application to save the image
    return this.http.post<any>(url, formdata, {
      reportProgress: true,
      observe: 'events',
      headers: headers
    }).pipe(
      map((event) => {
        if (event.type === HttpEventType.UploadProgress && argPersonId != null && argPersonId > 0) {
          const progress = Math.round(100 * event.loaded / event.total);
          return {status: 'progress', message: progress};
        } else if (event.type === HttpEventType.Response) {
          return event.body;
        } else {
          return {status: 'error', message: 0};
        }
      }),
      catchError(this.errorService.handleError('Not Found Type Error', [])), // then handle the error
    );
  }

  getImage(argPersonId: number): Observable<ProfileImg> {
    const url = GET_PROFILE_IMG_URI + `/${argPersonId}`;
    return this.http.get<ProfileImg>(url)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', new ProfileImg())) // then handle the error
      );
  }

}
