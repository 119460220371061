/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */


import {PatCurrStatus} from "@/app/shared/model/PatCurrStatus";
import {PatDependent} from "@/app/shared/model/patDependent";
import {PaymentOptions} from "@/app/shared/model/PaymentOptions";
import {SlotDtls} from "@/app/shared/model/SlotDtls";

export class CallsMaster {

  callId: number;
  callStatus: string;
  docAssignedDt: Date;
  callCancelledDt: Date;
  callReassignedDt: Date;
  docAcceptedDt: Date;
  docCallStartedDt: Date;
  docCompletedDt: Date;
  docId: number;
  docName: string;
  sendEmail: string;
  sendSms: string;
  personId: number;
  personName: string;
  couponId: number;
  patDependent: PatDependent;
  patCurrStatus = new PatCurrStatus();
  paymentOptions: PaymentOptions;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  slotDtls: SlotDtls;


}
