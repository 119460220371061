/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';

import {AdminDashBoardStatistics} from '../../shared/model/AdminDashBoardStatistics';
import {catchError} from 'rxjs/operators';
import {tap} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DashboardStatistics} from '../model/DashboardStatistics';
import {CallsMaster} from '../model/CallsMaster';
import {DocketDetails} from '../model/docketDetails';
import {GlobalErrorHandingService} from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};
const GET_CALLSMASTER_DASHBOARD_STATES_URI: string = environment.API_URL + '/callsMaster/dashboardStates';
const GET_CALLSMASTER_DASHBOARD_STATES_PAT_URI: string = environment.API_URL + '/callsMaster/dashboardStatesByPatId';
const CREATE_CALLSMASTER_URI: string = environment.API_URL + '/callcenter/create';
const UPDATE_CALLSMASTER_URI: string = environment.API_URL + '/callcenter/update';
const GET_CALLSMASTER_BY_ID_URI: string = environment.API_URL + '/callsMaster/findCallsMasterByDocId';
const GET_CALLSMASTER_BY_PAT_IDs_URI: string = environment.API_URL + '/callsMaster/findCallsMasterByPatientId';
const GET_CALLSMASTER_FOR_ADMIN_URI: string = environment.API_URL + '/callsMaster/getAllCallsMasterNotCompleted';
const GET_CALLSMASTER_ALL_URI: string = environment.API_URL + '/callcenter/all';
const GET_CALLSMASTER_BY_USER_AND_DATES_URI: string = environment.API_URL + '/callsMaster/findCallsMasterByDocIdAndDates';

const UPDATE_CALLSMASTER_CALLSTATUS_BY_ID_URI: string = environment.API_URL + '/callsMaster/updateStatus';
const UPDATE_CALLSMASTER_DOC_ASSIGN_BY_ID_URI: string = environment.API_URL + '/callsMaster/assignToDoc';


@Injectable()
export class CallsMasterService {

  nodatafound: boolean = false;
  callsMaster = new CallsMaster;
  callsMasterAlerts: Array<any> = [];

  private callDtlSubject = new BehaviorSubject<CallsMaster>(this.callsMaster);
  cast = this.callDtlSubject.asObservable();

  private formModeSubject = new BehaviorSubject<string>('CRT');
  formModeCast = this.formModeSubject.asObservable();

  constructor(private http: HttpClient,  private errorService: GlobalErrorHandingService ) {
  }

  changeFormMode(mode) {
    this.formModeSubject.next(mode);
  }

  // update call form by call list data
  editCallsMaster(argCallrecord: CallsMaster) {
    console.log('Received in Call Service :' + argCallrecord);
    this.callDtlSubject.next(argCallrecord);
    console.log(argCallrecord);
  }


  // App User Creation
  createCallsMaster(argCallsMaster: CallsMaster): Observable<CallsMaster> {
    const url = CREATE_CALLSMASTER_URI;
    console.log(url + ' ' + argCallsMaster.docId + argCallsMaster.docCompletedDt + ' Second log');
    return this.http.post<CallsMaster>(url, argCallsMaster, httpOptions)
      .pipe(
        tap(_ => {
          this.alertArrayPush('success', 'Call is successfully created !');
          console.log(`Call Details Created`);
        }),
        catchError(this.errorService.handleError('createCallsMaster', argCallsMaster)) // then handle the error
      );
  }

  // Update Address Details
  updateCallsMaster(argCallsMaster: CallsMaster): Observable<CallsMaster> {
    const url = UPDATE_CALLSMASTER_URI + `/${argCallsMaster.callId}`;
    return this.http.post<CallsMaster>(url, argCallsMaster, httpOptions)
      .pipe(
        tap(_ => {
          this.alertArrayPush('success', 'Call is successfully updated !');
          console.log(`Call Details Updated`);
        }),
        catchError(this.errorService.handleError('updateCallsMaster', argCallsMaster))
      );
  }

  // Get All Call List
  getCallList(): Observable<CallsMaster[]> {
    return this.http.get<CallsMaster[]>(GET_CALLSMASTER_ALL_URI)
      .pipe(
        tap(_ => console.log(`Call Details List Received`)),
        catchError(this.errorService.handleError('getCallList', [])) // then handle the error
      );
  }


  findCallsMasterByDocIdAndDates(argDocId: number, argFromDate: string, argToDate: string): Observable<CallsMaster[]> {
    const url = GET_CALLSMASTER_BY_USER_AND_DATES_URI + `/${argDocId}/${argFromDate}/${argToDate}`;
    return this.http.get<CallsMaster[]>(url)
      .pipe(
        tap(_ => console.log(`Call Details List Received`)),
        catchError(this.errorService.handleError('getCallList', [])) // then handle the error
      );
  }



  // Get All Call List
  getCallsMasterByDocId(argAppUserId: number): Observable<CallsMaster[]> {
    const url = GET_CALLSMASTER_BY_ID_URI + `/${argAppUserId}`;
    return this.http.get<CallsMaster[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls assigned to a doctor`)),
        catchError(this.errorService.handleError('getNewCallList', [])) // then handle the error
      );
  }
  getCallsMasterForAdmin(): Observable<CallsMaster[]> {
    const url = GET_CALLSMASTER_FOR_ADMIN_URI;
    return this.http.get<CallsMaster[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls assigned to a doctor`)),
        catchError(this.errorService.handleError('getNewCallList', [])) // then handle the error
      );
  }
    // Get All Call List
    getCallsMasterByPatId(argAppUserId: any): Observable<CallsMaster[]> {
      const url = GET_CALLSMASTER_BY_PAT_IDs_URI + `/${argAppUserId}`;
      return this.http.get<CallsMaster[]>(url)
        .pipe(
          tap(_ => console.log(`Find calls of`)),
          catchError(this.errorService.handleError('getNewCallList', [])) // then handle the error
        );
    }


  // Change Call status of App user /callId/callStatus/appUserId.
  changeCallStatus(argCallId: number, argCallStatus: string, argUpdatedBy: number): Observable<CallsMaster> {
    const url = UPDATE_CALLSMASTER_CALLSTATUS_BY_ID_URI + `/${argCallId}/${argCallStatus}/${argUpdatedBy}`;
    return this.http.post<CallsMaster>(url, httpOptions)
      .pipe(
        tap(_ => console.log(`Call Status change`)),
        catchError(this.errorService.handleError('changeCallStatus', null))
      );
  }

  // Change Call status of App user /callId/callStatus/appUserId.
  assignToDoc(argCallId: number, argDocId: number, argUserId: number): Observable<CallsMaster> {
    const url = UPDATE_CALLSMASTER_DOC_ASSIGN_BY_ID_URI + `/${argCallId}/${argDocId}/${argUserId}`;
    return this.http.post<CallsMaster>(url, httpOptions)
      .pipe(
        tap(_ => console.log(`Call Status change`)),
        catchError(this.errorService.handleError('changeCallStatus', null))
      );
  }


  // Get All Call List
  getDashboardStatesList(): Observable<DashboardStatistics[]> {
    return this.http.get<DashboardStatistics[]>(GET_CALLSMASTER_DASHBOARD_STATES_URI)
      .pipe(
        tap(_ => console.log(`Find calls statistics for the dashboard`)),
        catchError(this.errorService.handleError('getDashboardStatesList', [])) // then handle the error
      );
  }

  // Get Doctor DashBoard Statistics Call List
  getDocDashboardStatesList(argDocId: number, dateSince: string): Observable<DashboardStatistics[]> {
    const url = GET_CALLSMASTER_DASHBOARD_STATES_URI + `/${argDocId}/${dateSince}`;
    console.log('statistc url ' + url);
    return this.http.get<DashboardStatistics[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls statistics for the Doctor dashboard`)),
        catchError(this.errorService.handleError('getDocDashboardStatesList', [])) // then handle the error
      );
  }


  // Get Doctor DashBoard Statistics Call List
  getPatDashboardStatesList(argPatId: number, dateSince: string): Observable<DashboardStatistics[]> {
    const url = GET_CALLSMASTER_DASHBOARD_STATES_PAT_URI + `/${argPatId}/${dateSince}`;
    console.log('statistc url ' + url);
    return this.http.get<DashboardStatistics[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls statistics for the Doctor dashboard`)),
        catchError(this.errorService.handleError('getDocDashboardStatesList', [])) // then handle the error
      );
  }


  // Get Admin DashBoard Statistics AdminDashBoardStatistics List
  // tslint:disable-next-line:max-line-length
  getAdminDashChatDataList(dataType: string, docId: number, gardataStationId: number, dateFrom: string, dataTo: string): Observable<AdminDashBoardStatistics[]> {
    const url = GET_CALLSMASTER_DASHBOARD_STATES_URI + `/${dataType}/${docId}/${gardataStationId}/${dateFrom}/${dataTo}`;
    console.log('Admin Dashboard Statistics url ' + url);
    return this.http.get<AdminDashBoardStatistics[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls statistics for the Admin dashboard`)),
        catchError(this.errorService.handleError('getAdminDashChatDataList', [])) // then handle the error
      );
  }

// ****Alert message Array push and close methods** - Started**/
  public alertArrayPush(type, message) {
    this.callsMasterAlerts.push(
      {
        type: type,
        message: message
      }
    );
  }

  public closeAlert(alert: any) {
    const index: number = this.callsMasterAlerts.indexOf(alert);
    this.callsMasterAlerts.splice(index, 1);
  }

  /*  //Send SMS
    sendSMS(text: string, toMobilePhone: string): Observable<string> {
     // const url = SEND_SMS_NEON + `&text="` + text + `"&to=` + toMobilePhone;
      //console.log('SMS full url: ' + url);
      return this.http.get<string>(url)
        .pipe(
          tap(_ => {
            this.alertArrayPush('success', 'SMS is sent successfully !');
            console.log(`SMS sent successfully to: ` + toMobilePhone);
          }),
          catchError(this.errorService.handleError('sendSMS', '')) // then handle the error
        );
    }*/

}

