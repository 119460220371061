
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {inject, Injectable} from "@angular/core";
import {tap} from "rxjs/operators";
import {ErrorHandlerSnakBar} from "@/app/shared/ErrorHandler/ErrorHandlerSnakBar";
import {environment} from "src/environments/environment";
import {AuthGuardService} from "./authGuard.service";


import {Observable, throwError} from "rxjs";

@Injectable()
export class authGuardInterceptor implements HttpInterceptor {

  constructor(
    public errorHandlerSnakBar: ErrorHandlerSnakBar,
    private authService: AuthGuardService
) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();
debugger
    if (authToken) {
      req = req.clone({
        setHeaders: {
          [environment.ACCESS_TOKEN_HEADER_KEY]: `Bearer ${authToken}`,
        },
      });
    }


    return next.handle(req).pipe(
      tap((event => {
          if (event instanceof HttpResponse) {
          }
        }),
        (error: any) => {
          if (404 === error.status) {
            // this.errorHandlerSnakBar.handleError(error.error.error + ' ==> Bingo!!.');
          } else if (400 === error.status && error.error.error === ' invalid_grant ') {
            this.errorHandlerSnakBar.handleError(error.status + ' ==> UserName OR Password are incorrect!!.');
          } else if (error.status === 401 || error.status === 403) {
            // auto logout if 401 response returned from api
            this.errorHandlerSnakBar.handleError(error.status + ' ==> Security Token is Expired, Please Login Again!!.');
            this.authService.logout();
          } else if (error instanceof HttpErrorResponse) {
            this.errorHandlerSnakBar.handleError(error.status + ' ==>Continue your work, Click to close !');
          }
        }));

  }
}
