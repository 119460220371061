/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';

import {AdminDashBoardStatistics} from '../../shared/model/AdminDashBoardStatistics';
import {catchError} from 'rxjs/operators';
import {tap} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {CallDetails} from '../model';
import {environment} from '../../../environments/environment';
import {DashboardStatistics} from '../model/DashboardStatistics';
import {GlobalErrorHandingService} from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};
const GET_CALLCENTER_DASHBOARD_STATES_URI: string = environment.API_URL + '/callcenter/dashboardStates';
const CREATE_CALLCENTER_URI: string = environment.API_URL + '/callcenter/create';
const UPDATE_CALLCENTER_URI: string = environment.API_URL + '/callcenter/update';
const GET_CALLCENTER_BY_ID_URI: string = environment.API_URL + '/callcenter/findByUser';
const GET_CALLCENTER_ALL_URI: string = environment.API_URL + '/callcenter/all';
const GET_CALLCENTER_BY_USER_AND_DATES_URI: string = environment.API_URL + '/callcenter/findCallDetailsByDates';

const UPDATE_CALLSTATUS_BY_ID_URI: string = environment.API_URL + '/callcenter/updateStatus';


@Injectable()
export class CallDetailsService {

  nodatafound: boolean = false;
  callDetails = new CallDetails;
  callDetailsAlerts: Array<any> = [];

  private callDtlSubject = new BehaviorSubject<CallDetails>(this.callDetails);
  cast = this.callDtlSubject.asObservable();

  private formModeSubject = new BehaviorSubject<string>('CRT');
  formModeCast = this.formModeSubject.asObservable();

  constructor(private http: HttpClient,  private errorService: GlobalErrorHandingService ) {
  }

  changeFormMode(mode) {
    this.formModeSubject.next(mode);
  }

  // update call form by call list data
  editCallDetails(callrecord: CallDetails) {
    console.log('Received in Call Service :' + callrecord);
    this.callDtlSubject.next(callrecord);
    console.log(callrecord);
  }


  // App User Creation
  createCallDetails(callDetails: CallDetails): Observable<CallDetails> {
    const url = CREATE_CALLCENTER_URI;
    console.log(url + ' ' + callDetails.docId + callDetails.docSubmitDt + ' Second log');
    return this.http.post<CallDetails>(url, callDetails, httpOptions)
      .pipe(
        tap(_ => {
          this.alertArrayPush('success', 'Call is successfully created !');
          console.log(`Call Details Created`);
        }),
        catchError(this.errorService.handleError('createCallDetails', callDetails)) // then handle the error
      );
  }

  // Update Address Details
  updateCallDetails(callDetailsEdit: CallDetails): Observable<CallDetails> {
    const url = UPDATE_CALLCENTER_URI + `/${callDetailsEdit.callId}`;
    return this.http.post<CallDetails>(url, callDetailsEdit, httpOptions)
      .pipe(
        tap(_ => {
          this.alertArrayPush('success', 'Call is successfully updated !');
          console.log(`Call Details Updated`);
        }),
        catchError(this.errorService.handleError('updateCallDetails', callDetailsEdit))
      );
  }

  // Get All Call List
  getCallList(): Observable<CallDetails[]> {
    return this.http.get<CallDetails[]>(GET_CALLCENTER_ALL_URI)
      .pipe(
        tap(_ => console.log(`Call Details List Received`)),
        catchError(this.errorService.handleError('getCallList', [])) // then handle the error
      );
  }


  getCallListByUserAndDates(docId: number, fromDate: string, toDate: string): Observable<CallDetails[]> {
    const url = GET_CALLCENTER_BY_USER_AND_DATES_URI + `/${docId}/${fromDate}/${toDate}`;
    return this.http.get<CallDetails[]>(url)
      .pipe(
        tap(_ => console.log(`Call Details List Received`)),
        catchError(this.errorService.handleError('getCallList', [])) // then handle the error
      );
  }

  // Get All Call List
  getNewCallList(appUserId: number): Observable<CallDetails[]> {
    const url = GET_CALLCENTER_BY_ID_URI + `/${appUserId}`;
    return this.http.get<CallDetails[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls assigned to a doctor`)),
        catchError(this.errorService.handleError('getNewCallList', [])) // then handle the error
      );
  }


  // Change Call status of App user /callId/callStatus/appUserId.
  changeCallStatus(callId: number, callStatus: string, appUserId: number): Observable<CallDetails> {
    const url = UPDATE_CALLSTATUS_BY_ID_URI + `/${callId}/${callStatus}/${appUserId}`;
    return this.http.post<CallDetails>(url, callId, httpOptions)
      .pipe(
        tap(_ => console.log(`Call Status change`)),
        catchError(this.errorService.handleError('changeCallStatus', null))
      );
  }

  // Get All Call List
  getDashboardStatesList(): Observable<DashboardStatistics[]> {
    return this.http.get<DashboardStatistics[]>(GET_CALLCENTER_DASHBOARD_STATES_URI)
      .pipe(
        tap(_ => console.log(`Find calls statistics for the dashboard`)),
        catchError(this.errorService.handleError('getDashboardStatesList', [])) // then handle the error
      );
  }

  // Get Doctor DashBoard Statistics Call List
  getDocDashboardStatesList(docId: number, dateSince: string): Observable<DashboardStatistics[]> {
    const url = GET_CALLCENTER_DASHBOARD_STATES_URI + `/${docId}/${dateSince}`;
    console.log('statistc url ' + url);
    return this.http.get<DashboardStatistics[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls statistics for the Doctor dashboard`)),
        catchError(this.errorService.handleError('getDocDashboardStatesList', [])) // then handle the error
      );
  }


  // Get Admin DashBoard Statistics AdminDashBoardStatistics List
  getAdminDashChatDataList(dataType: string, docId: number, gardataStationId: number, dateFrom: string, dataTo: string): Observable<AdminDashBoardStatistics[]> {
    const url = GET_CALLCENTER_DASHBOARD_STATES_URI + `/${dataType}/${docId}/${gardataStationId}/${dateFrom}/${dataTo}`;
    console.log('Admin Dashboard Statistics url ' + url);
    return this.http.get<AdminDashBoardStatistics[]>(url)
      .pipe(
        tap(_ => console.log(`Find calls statistics for the Admin dashboard`)),
        catchError(this.errorService.handleError('getAdminDashChatDataList', [])) // then handle the error
      );
  }

// ****Alert message Array push and close methods** - Started**/
  public alertArrayPush(type, message) {
    this.callDetailsAlerts.push(
      {
        type: type,
        message: message
      }
    );
  }

  public closeAlert(alert: any) {
    const index: number = this.callDetailsAlerts.indexOf(alert);
    this.callDetailsAlerts.splice(index, 1);
  }

  /*  //Send SMS
    sendSMS(text: string, toMobilePhone: string): Observable<string> {
     // const url = SEND_SMS_NEON + `&text="` + text + `"&to=` + toMobilePhone;
      //console.log('SMS full url: ' + url);
      return this.http.get<string>(url)
        .pipe(
          tap(_ => {
            this.alertArrayPush('success', 'SMS is sent successfully !');
            console.log(`SMS sent successfully to: ` + toMobilePhone);
          }),
          catchError(this.errorService.handleError('sendSMS', '')) // then handle the error
        );
    }*/

}

