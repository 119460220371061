/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */


import {Person} from "@/app/shared";
import {CallsMaster} from "@/app/shared/model/CallsMaster";
import {SchedulerMaster} from "@/app/shared/model/SchedulerMaster";
import {GlobalErrorHandingService} from "@/app/shared/ErrorHandler/GlobalErrorHanding.service";
import {environment} from "@/environments/environment";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";

const CREATE_CALLSMASTER_URI: string = environment.API_URL + '/callsMaster/create';
const GET_DOC_LIST: string = environment.API_URL + '/person/findDocBySpecialty';
const GET_DOC_INFO_LIST: string = environment.API_URL + '/person/findDocInfoBySpecialty';
const GET_DOC_SLOTS: string = environment.API_URL + '/scheduler/findByDocId';
const GET_PERSON_BY_ID: string = environment.API_URL + '/callsMaster/findByPersonId';

@Injectable()
export class SeeDrNowService {
  callsMaster: CallsMaster = new CallsMaster;
  isSh = false;
  callmasterSendFlag = false;
  responseCallMaster: CallsMaster;

  constructor(private http: HttpClient,  private errorService: GlobalErrorHandingService ) {
  }

  // Create CallsMaster
  createCallsMaster(): Observable<CallsMaster> {
    console.log(this.callsMaster);
    this.callsMaster.callStatus = 'LOGD';
    if (this.callsMaster.slotDtls === undefined || this.callsMaster.slotDtls === null ) {
      this.callsMaster.docId = null;
      this.callsMaster.slotDtls = null;
    }
    const url = CREATE_CALLSMASTER_URI;
    return this.http.post<CallsMaster>(url, this.callsMaster)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', new CallsMaster())) // then handle the error
      );
  }

  getDocList(argMedSpecialtyCode): Observable<Person[]>  {
    const url = GET_DOC_INFO_LIST + `/${argMedSpecialtyCode}`;
    return this.http.get<Person[]>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  getDocInfoList(argMedSpecialtyCode): Observable<Person[]>  {
    const url = GET_DOC_INFO_LIST + `/${argMedSpecialtyCode}`;
    return this.http.get<Person[]>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  getDocSlots(personId, date): Observable<SchedulerMaster>  {
    const url = GET_DOC_SLOTS + `/${personId}` + `/${date}`;
    return this.http.get<SchedulerMaster>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', new SchedulerMaster())) // then handle the error
      );
  }

  getSchedulerMasterByDocId(personId): Observable<SchedulerMaster[]>  {
    const url = GET_DOC_SLOTS + `/${personId}`;
    return this.http.get<SchedulerMaster[]>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', new Array<SchedulerMaster>())) // then handle the error
      );
  }

  findByPersonId(personId): Observable<CallsMaster[]>  {
    const url = GET_PERSON_BY_ID + `/${personId}`;
    return this.http.get<CallsMaster[]>(url)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  public setPatDependent(patDependent: any): void {
    this.callsMaster.personId = patDependent.personId;
  }
  public getPatPersonId() {
    return this.callsMaster.personId;
  }
  public setSheduleCall(argSelectedSlotDtls) {
    this.callsMaster.slotDtls = argSelectedSlotDtls;
  }
  public setPatCurStatus(PatCurStatus: any): void {
    this.callsMaster.patCurrStatus = PatCurStatus;
  }
  public setPayment(Payment: any): void {
    this.callsMaster.paymentOptions = Payment;
  }

}
