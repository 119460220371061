/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

export class AddressDetails {
  //metadata
  public active: string;
  public addr1: string;
  public addr2: string;
  public addressCode: string;
  public addressId: number;
  //metadata
  public addressType: string;
  public city: string;
  public companyName: string;
  //metadata TODO
  public country: string;
  //metadata TODO
  public county: string;
  public eMail: string;
  public faxNo: string;
  public fullName: string;
  public latitude: number;
  public longitude: number;
  public personId: number;
  public phoneMobile: string;
  public phoneWork: string;
  public postalCode: string;
  //metadata
  public preferred: string;
  public createdBy: number;
  public createdDate: Date;
  public lastModifiedBy: number;
  public lastModifiedDate: Date;
}
