/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {VideoCallService} from "@/app/shared/websocket/VideoCallService";
import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


export interface DialogData {
  callType: number;
  patId: number;
  docId: number;
  fromTo: string;
  channelName: string;
}

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {
  title = 'angular-video';
  localCallId = 'agora_local';
  remoteCalls: string[] = [];
  channelName: string;
  muteFlag = false;
  sizeFlag = false;


  constructor(public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private videoCallService: VideoCallService) {
  }

  ngOnInit() {
    console.log(this.remoteCalls);
    this.channelName = this.data.channelName;
    console.log(this.channelName);
    this.videoCallService.initialiseCall(this.channelName, this.data.callType);
    this.localCallId = this.videoCallService.localCallId;
    this.remoteCalls = this.videoCallService.remoteCalls;
  }

  async onCallDisconnect() {
    // this.videoCallService.leave();
    await this.videoCallService.leaveChannel();
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
  mute() {
    this.muteFlag = !this.muteFlag;
/*    if (this.muteFlag === true) {
      this.videoCallService.muteAudio();
    }
    if (this.muteFlag === false) {
      this.videoCallService.unmuteAudio();
    }*/
  }

}
