/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {AddressDetails} from './addressdetails';
import {AppUser} from './appuser';
import {PatientHealthInfo} from './PatientHealthInfo';
import { HospitalAffiliation } from './HospitalAffiliation';
import { Qualification } from './Qualification';
import { ProfileImg } from './ProfileImg';

export class Person {
  // metadata
  public active: string;
  public age: number;
  public dateOfBirth: Date;
  public email: string;
  public faxNo: string;
  public firstName: string;
  // metadata
  public gender: string;
  public lastName: string;
  public personId: number;
  // metadata
  public personType: string;
  public phoneHome: string;
  public phoneMobile: string;
  public phoneWork: string;

  public mcrn: string;
  public otherMedicalRegs: string;
  public insuranceNo: string;
  public insuranceCompName: string;
  public companyName: string;
  public companyRegNo: string;
  public others: string;
  public dateOfJoining: Date;
  public gpName: string;
  public pharmacyName: string;
  public documentSetId: number;
  public appUserId: number;

  public mdSpecialties: string[] = new Array<string>();
  public mdServices: string[] = new Array<string>();
  public practicingFrom: Date;
  public profileDesc: string;
  public professionalStatement: string;
  // metadata
  public title: string;
  public createdBy: number;
  public createdDate: Date;
  public lastModifiedBy: number;
  public lastModifiedDate: Date;
  // public addressDetailsList:AddressDetails[]=[];
  public addressDetailsList: AddressDetails[] = new Array<AddressDetails>();
  public hospitalAffiliationList: HospitalAffiliation[] = new Array<HospitalAffiliation>();
  public qualificationList: Qualification[] = new Array<Qualification>();
  public profileImg: ProfileImg;
  public appUser: AppUser;

  public parentPersonId: number;
  public patientHealthInfo: PatientHealthInfo;
  public dependentsList: Person[] = new Array<Person>();
}
