/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  title: string;
}

@Component({
  selector: 'app-Video-call-message-dialog',
  templateUrl: './Video-call-message-dialog.component.html',
  styleUrls: ['./Video-call-message-dialog.component.scss']
})

export class VideoCallMessageDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VideoCallMessageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
  }

  onCallReject(): void {
    this.dialogRef.close(false);
  }

  onCallAccept(): void {
    this.dialogRef.close(true);
  }


}
