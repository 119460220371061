/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {AuthService} from "@/app/shared";
import {AuthGuardService} from "@/app/shared/guard/authGuard.service";
import {AuthProvider} from "@/app/shared/model/AuthProvider";
import {LoginRequest} from "@/app/shared/model/LoginRequest";
import {Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

import {slideInAnimation} from './router-animations';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [ slideInAnimation ],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {



  token!: string;
  error!: string;
  authProvider: AuthProvider = AuthProvider.provider;
  constructor( private sanitizer: DomSanitizer,
               private matIconRegistry: MatIconRegistry,
               private authGuardService: AuthGuardService,
               private authSnv: AuthService) {
    }

    ngOnInit() {
      this.authGuardService.removeTokensAndResetFlags();
      this.initSvgIcons();
      this.getToken();
    }

  private initSvgIcons() {
    this.matIconRegistry.addSvgIcon(
      'google_logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/google_logo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'github_logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/github_logo.svg'
      )
    );
  }

  getToken() {
    const loginRequest = new LoginRequest();
    loginRequest.email = 'webuser@zaitech.ie';
    loginRequest.password = 'ZaiTEC@DocHub202$!4';

    const token =   this.authGuardService.getToken();
    if(token === undefined || token ==null ){
      this.authSnv.login(loginRequest)
        .subscribe({
          next: data => {
            const token = JSON.parse(JSON.stringify(data)).accessToken;
            if (token) {
              this.authProvider = AuthProvider.local;
              this.authGuardService.setAuthentication(token);
            }
          }
        });
    }

  }
}
