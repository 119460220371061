<!--
  ~  ZAITEC CONFIDENTIAL
  ~  __________________
  ~
  ~ [2019] - [2020] zaiTEC Logixs Ltd
  ~ All Rights Reserved.
  ~
  ~ NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
  ~  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
  ~  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
  ~  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
  -->

<h1 mat-dialog-title>Confirmation!</h1>
<div mat-dialog-content>
  <p>{{data.title}}</p>
  <!-- <embed name="GoodEnough" src="../../../../assets/images/iphone-43336.mp3" loop="true" hidden="true" autostart="true"> -->
    <audio  autoplay loop="true">
      <source src="../../../../assets/images/iphone-43336.mp3"  type="audio/ogg">
     </audio>
</div>
<div mat-dialog-actions align="center">
  <button mat-fab [mat-dialog-close]="" (click)="onCallReject()" cdkFocusInitial color="warn">
    <mat-icon matTooltip="Reject call">call_end</mat-icon>
  </button>
  <button mat-fab [mat-dialog-close]="" style="background-color: #28A745; color: aliceblue;" (click)="onCallAccept()"
    cdkFocusInitial>
    <mat-icon matTooltip="Accept call">call</mat-icon>
  </button>
</div>
