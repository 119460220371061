/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { AppUser } from '../../shared/model/index';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Activity } from '../model/Activity';
import {CustomHttpParamEncoder} from './CustomHttpParamEncoder';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

const GET_ALLUSER_ACTIVITIES_URI: string = environment.API_URL + '/user-activity/all';
const CREATE_USERACTIVITY_URI: string = environment.API_URL + '/user-activity/create';
const GET_FINDBYUSERNAME_URI: string = environment.API_URL + '/user-activity/findByAppUser';
const GET_FINDBYUSERNAMEANDDATES_URI: string = environment.API_URL + '/user-activity/findByAppUserAndDates';


@Injectable()
export class ActivityLogsService {

  constructor(private http: HttpClient) {
  }



  LOG(desc: string, argAppUserId: number) {
    this.createAppUserActivityLog(desc, argAppUserId)
      .subscribe(response => {
        // console.log(response);
      });
  }
  // Get All App Users List Activities
  getAllAppUserActivityLogs() {
    return this.http.get<Activity[]>(GET_ALLUSER_ACTIVITIES_URI)
      .pipe(
        catchError(this.handleError()) // then handle the error
      );
  }

  // Create AppUserActivity
  createAppUserActivityLog(argDesc: string, argAppUserId: number) {

    let params = new HttpParams({encoder: new CustomHttpParamEncoder()});
    params = params.append('action_desc', argDesc);
    params = params.append('userId', argAppUserId.toString());

    const url = CREATE_USERACTIVITY_URI + '?' + params.toString();
    return this.http.post<any>(url, params , httpOptions);
  }

  // Get All App Users Activities List By name
  getAppUserActivityLogsByAppUserName(argUserName: any) {
    if (argUserName === 'all') {
      return this.getAllAppUserActivityLogs();
    } else {
      const url = GET_FINDBYUSERNAME_URI + `/${argUserName}`;
      return this.http.get<Activity[]>(url, httpOptions)
        .pipe(
          catchError(this.handleError('getAppUserActivityLogsByAppUserName', []))
        );
    }
  }

  // Get All App Users Activities List By name and Dates
  getAppUserActivityLogsByUserNameAndDates(argUserName: any, argStartDate: string, argEndDate: string): Observable<Activity[]> {
    const url = GET_FINDBYUSERNAMEANDDATES_URI + `/${argUserName}` + `/${argStartDate}` + `/${argEndDate}`;
    return this.http.get<Activity[]>(url, httpOptions)
      .pipe(
        catchError(this.handleError('getAppUserActivityLogsByUserNameAndDates', []))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
