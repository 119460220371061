/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

export class PatCurrStatus {
    public reason: string;
    public phone: string;
    public hasAllergies: string;
    public currentMedication: string[];
    public county: string;
    public country: string;
    public callSource: number;
    public allergicFrom: string[];
    public hasMdConditions: string;
    public phoneNumber: string;
    public medConditionsDtls: string;
}
