import {environment} from "@/environments/environment";
import {Inject, Injectable} from '@angular/core';
import AgoraRTC, {ILocalAudioTrack, ILocalVideoTrack, IAgoraRTCClient, UID} from "agora-rtc-sdk-ng";


@Injectable()
export class VideoCallService {


  localCallId = 'local-video-container';
  remoteCalls: string[] = [];
  channelName: string;
  callFlag: string = '';

  private client: IAgoraRTCClient;
  private uid: number;

  localAudioTrack
  localVideoTrack;

  private appId = environment.AGORA_APP_ID
  private ringingAudio: HTMLAudioElement;


  constructor() {
    /** @type {ILocalAudioTrack} */
    this.localAudioTrack = null;
    /** @type {ILocalVideoTrack} */
    this.localVideoTrack = null;

  }

  initialiseCall(argChannelName: string, argCallType: number) {
    this.channelName = argChannelName;
    console.log(this.channelName);
    this.uid = Math.floor(Math.random() * 1000);
    // this.uid = argUid;
    console.log('uid is:' + this.uid);


    this.client = AgoraRTC.createClient({mode: 'rtc', codec: 'h264'});
    this.ringingAudio = new Audio('../../../../assets/images/simple_beep.mp3'); // Update to your audio file
    this.ringingAudio.loop = true; // Set audio to loop

    this.setupLocalTracks();
    // Bind event handlers
    this._setupEventListeners(argCallType);
    // Play ringing audio
    this.playRingingAudio();

    this.joinChannel(this.appId,  this.channelName , null, this.uid );

    // Handle connection state changes
    this.client.on("connection-state-change", (curState, prevState) => {
      console.log("Connection state changed:", prevState, "->", curState);
      // Stop ringing audio when connected
      if (curState === "CONNECTED") {
        this.stopRingingAudio();
      }
    });
  }


  // Method to set up local audio and video tracks
  async setupLocalTracks() {
    [this.localAudioTrack, this.localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
    console.log("Audio Track:", this.localAudioTrack);
    console.log("Video Track:", this.localVideoTrack);
    // Check if tracks are created successfully
    if (this.localAudioTrack && this.localVideoTrack) {
      await this.client.publish([this.localAudioTrack, this.localVideoTrack]);
    } else {
      console.error("Failed to create local tracks.");
    }

    // Play the local video track in a specified DOM element
    this.localVideoTrack.play(this.localCallId);
    console.log("Local tracks created and playing.");
  }


  // Method to join a channel and publish local tracks
  async joinChannel(appId, channel, token, uid) {
    await this.client.join(appId, channel, token, uid);
    console.log(`Joined channel: ${channel}`);

    // Publish local tracks to the channel
    await this.client.publish([this.localAudioTrack, this.localVideoTrack]);
    console.log("Local tracks published.");
  }


  // Private method to set up event listeners for remote users
  _setupEventListeners(argCallType: number) {


    if (argCallType === 0) {
      this.callFlag =  "video";
    } else {
      this.callFlag = "audio";
    }

    this.client.on("user-published", async (user, mediaType) => {
      // Subscribe to the remote user's track
      await this.client.subscribe(user, mediaType);
      console.log("Subscribed to user:", user.uid);
      const id = this.getRemoteId(user.uid);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
      }

      // Play the remote track depending on its media type
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        // Create a new div element for the remote video
        remoteVideoTrack.play(id);
      }
      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });


    this.client.on("user-unpublished", (user) => {
      console.log("User unpublished:", user.uid);
      this.remoteCalls = [];
      // Remove the remote user's video element from the DOM
/*      const videoItem = document.getElementById(`remote-user-${user.uid}`);
      if (videoItem) {
        videoItem.remove(); // Remove the user's video div when they leave
      }*/
    });
  }



  // Method to leave the channel and clean up resources
  async leaveChannel() {


    // Unpublish all local tracks if they are published
    if (this.client.localTracks.includes(this.localAudioTrack) || this.client.localTracks.includes(this.localVideoTrack)) {
      await this.client.unpublish([this.localAudioTrack, this.localVideoTrack]);
      console.log("Tracks unpublished from the channel");
    }

    this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(this.uid)}`);

    // Leave the Agora channel
    await this.client.leave();
    console.log("Left the channel");

    // Stop and close local video and audio tracks
    if (this.localAudioTrack) {
      this.localAudioTrack.stop();  // Stops capturing from the microphone
      this.localAudioTrack.close(); // Releases the audio device resources
      console.log("Audio track stopped and closed");
    }

    if (this.localVideoTrack) {
      this.localVideoTrack.stop();  // Stops capturing from the camera
      this.localVideoTrack.close(); // Releases the video device resources
      console.log("Video track stopped and closed");
    }



    console.log("Local Audio Track:", this.localAudioTrack);
    console.log("Local Video Track:", this.localVideoTrack);
    this.localAudioTrack = null;
    this.localVideoTrack = null;

    // this.stopRingingAudio();
    this.remoteCalls = [];
    console.log("Left the channel and cleaned up resources.");
  }

  private playRingingAudio() {
    this.ringingAudio.play().catch(error => {
      console.error("Error playing audio:", error);
    });
  }

  private stopRingingAudio() {
    this.ringingAudio.pause();
    this.ringingAudio.currentTime = 0; // Reset audio playback to start
  }


  private getRemoteId(userID: UID): string {
    return `remote-user-${userID}`;
  }

}
