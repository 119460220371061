/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';

const routes: Routes = [
    { path: 'site', loadChildren: () => import('../app/website/website.module').then(m => m.WebsiteModule), data: {animation: 'website' }},
    // { path: 'site/whome', loadChildren: () => import('../app/website/website.module').then(m => m.WebsiteModule) },
    // { path: 'site/doctors', loadChildren: () => import('../app/website/website.module').then(m => m.WebsiteModule) },
    // { path: 'site/medical_services', loadChildren: () => import('../app/website/website.module').then(m => m.WebsiteModule) },

    /* { path: 'login', loadChildren: () => import('./module-compo/admin/login/login.module').then(m => m.LoginModule) },*/
    { path: 'SignUp', loadChildren: () => import('./module-compo/admin/sign-up/sign-up.module').then(m => m.SignupModule) },
    { path: 'forgotpassword', loadChildren: () => import('./module-compo/admin/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
    { path: 'user/validateToken', loadChildren: () => import('./module-compo/admin/update-password/update-password.module').then(m => m.UpdatePasswordModule) },
    // tslint:disable-next-line: max-line-length
    { path: 'user/validateSignUpEmail', loadChildren: () => import('./module-compo/admin/signup-verification/signup-verification.module').then(m => m.SignupVerificationModule) },
    { path: '', loadChildren: () => import('./module-compo/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard], data: {animation: 'dashboard'}},
    { path: 'error', loadChildren: () => import('./module-compo/admin/server-error/server-error.module').then(m => m.ServerErrorModule) },
    { path: 'access-denied', loadChildren: () => import('./module-compo/admin/access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'not-found', loadChildren: () => import('./module-compo/admin/not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found' },


];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64] // [x, y]
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
