/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

export class MetaData {
  //metadata
  public active: string;
  public categ: string;
  public code: string;
  public description: string;
  public displayOrder: number;
  //metadata
  public editable: string;
  public metaDataId: number;
  public createdBy: string;
  public createdDate: Date;
  public lastModifiedBy: string;
  public lastModifiedDate: Date;
}
