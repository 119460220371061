/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Directive} from '@angular/core';
import {AsyncValidatorFn, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidator} from '@angular/forms';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AppUserService} from '../services/index';



export function uniqueUsernameValidator(appUserService: AppUserService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return appUserService.validateUniqueUserName(control.value).pipe(
      map(resFlag => {
       // console.log('resFlag: ' + resFlag);
        return resFlag ? {'uniqueUsername': true} : null;
      })
    );
  };
}


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[UniqueUsername]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueUsernameValidatorDirective, multi: true}]
})
export class UniqueUsernameValidatorDirective {
  constructor(private appUserService: AppUserService) {
  }

}
