/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {AddressDetails} from '../model';
import {environment} from '../../../environments/environment';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {GlobalErrorHandingService} from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

const CREATE_ADDRESSDTLS_URI: string = environment.API_URL + '/addressdtls/create'; // URI for addressdtls create API
const UPDATE_ADDRESSDTLS_URI: string = environment.API_URL + '/addressdtls/update'; // URI for addressdtls update API
const DELETE_ADDRESSDTLS_URI: string = environment.API_URL + '/addressdtls/delete'; // URI for addressdtls Delete API
const GET_ADDRESSDTLSES_URI: string = environment.API_URL + '/addressdtls/addresses'; // URI for addressdtls all addresses API
const GET_FINDBYADDRESSTYPES_URI: string = environment.API_URL + '/addressdtls/findbyaddresstypes'; // URI for addressdtls by address
const GET_FINDBYADDRESS_BY_PERSON_ID_URI: string = environment.API_URL + '/addressdtls/findAddressByPersonId'; // URI for addressdtls by address

const GARDA_ADDRESS_TYPE_ID = '5';
const HOTEL_ADDRESS_TYPE_ID = '6';
const NURSINGHOME_ADDRESS_TYPE_ID = '7';

@Injectable()
export class AddressDetailsService {

  addressDetails = new AddressDetails;

  private addressDetailsSubject = new BehaviorSubject<AddressDetails>(this.addressDetails);
  addressDetailsSubjectCast = this.addressDetailsSubject.asObservable();

  private formModeSubject = new BehaviorSubject<string>('CRT');
  formModeCast = this.formModeSubject.asObservable();

  constructor(private http: HttpClient, private errorService: GlobalErrorHandingService ) {
  }

  changeAddressDetailsFormMode(mode) {
    this.formModeSubject.next(mode);
  }

  // update call form by call list data
  setAddressDetails(addressDetailsRecord: AddressDetails) {
    console.log('Received in Address Details Service :' + addressDetailsRecord);
    this.addressDetailsSubject.next(addressDetailsRecord);
  }

  // Create Address Details
  createAddressDetails(addressDetails: AddressDetails): Observable<AddressDetails> {
    const url = CREATE_ADDRESSDTLS_URI;
    console.log('AddressDetailsService.createAddressDetails :' + addressDetails.personId);
    return this.http.post<AddressDetails>(url, addressDetails, httpOptions)
      .pipe(catchError(this.errorService.handleError('Not Found Type Error', addressDetails)) // then handle the error
      );
  }

  // Update Address Details
  updateAddressDetails(addressDetailsEdit: AddressDetails): Observable<AddressDetails> {
    const url = UPDATE_ADDRESSDTLS_URI + `/${addressDetailsEdit.addressId}`;
    return this.http.post<AddressDetails>(url, addressDetailsEdit, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', addressDetailsEdit)) // then handle the error
      );
  }


  // Update Address Details
  deleteAddressDetails(argAddressDetails: AddressDetails): Observable<AddressDetails> {
    const url = DELETE_ADDRESSDTLS_URI + `/${argAddressDetails.addressId}`;
    return this.http.delete<AddressDetails>(url, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', argAddressDetails)) // then handle the error
      );
  }

  // Get All Address Details List
  getAllAddressDetailsList() {
    return this.http.get<AddressDetails[]>(GET_ADDRESSDTLSES_URI)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  // Get All Address Details List
  getAddressDetailsByAddressTypes() {
    const url = GET_FINDBYADDRESSTYPES_URI + `/${GARDA_ADDRESS_TYPE_ID + ',' + HOTEL_ADDRESS_TYPE_ID + ',' + NURSINGHOME_ADDRESS_TYPE_ID}`;
    return this.http.get<AddressDetails[]>(url)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }


  // Get All Address Details List
  getAddressByPersonId(argPersonId: number) {
    const url = GET_FINDBYADDRESS_BY_PERSON_ID_URI + `/${argPersonId}`;
    return this.http.get<AddressDetails[]>(url)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

  // Get Address Details Selected from the List
  getAddressDetailsRecord() {
    return this.http.get<AddressDetails>(GET_ADDRESSDTLSES_URI)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }



}
