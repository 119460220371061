/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {GlobalErrorHandingService} from "@/app/shared/ErrorHandler/GlobalErrorHanding.service";
import {AuthGuardService} from "@/app/shared/guard/authGuard.service";
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoginRequest} from "src/app/shared/model/LoginRequest";
import {UserResponse} from "src/app/shared/model/UserResponse";
import {OauthToken} from '../../shared/model/oauthToken';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AppUser} from '../../shared';
import {environment} from '../../../environments/environment';

import 'url-search-params-polyfill';
import {WebSocketMessage} from '../model/WebSocketMessage';
import {SharedDataService} from './shared.data.service';
import {WebSocketService} from '../websocket/WebSocketService';
import {Router} from '@angular/router';


const GET_OAUTH_TOKEN_URI: string = environment.API_URL + '/oauth/token';
const VALIDATE_APPUSERS_URI: string = environment.API_URL + '/appuser/validateappuser';
const CURRENT_APPUSER_URI: string = environment.API_URL + '/appuser/currentUser';
const CLIENT_ID_UNSERNAME: string = environment.CLIENT_ID_UNSERNAME;
const CLIENT_SECRET_PASSWORD: string = environment.CLIENT_SECRET_PASSWORD;
const GRANT_TYPE: string = environment.GRANT_TYPE;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};
@Injectable()
export class AuthService {
  // private headers = new Headers({'Content-Type': 'application/json'});
  public loggedIn;
  LoggedInUser: AppUser;

  constructor(private http: HttpClient,  private errorService: GlobalErrorHandingService,
              private webSocketService: WebSocketService,
              private sharedDataService: SharedDataService,
              public router: Router,
              private authGuardService: AuthGuardService
              ) {
    this.sharedDataService.loggedInUserCast
      .subscribe(LoggedInUser => this.LoggedInUser = LoggedInUser);
  }


  // App User Authorization
  getOAuthToken(userName: string, userPassword: string): Observable<any> {
    let params = new URLSearchParams();
    params.append('username', userName);
    params.append('password', userPassword);
    params.append('grant_type', GRANT_TYPE);

    let basicToken: string = btoa(CLIENT_ID_UNSERNAME + ':' + CLIENT_SECRET_PASSWORD);

    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + basicToken,
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
      })
    };



    // console.log(`getOAuthToken.params ` + params.toString());
    // console.log(`getOAuthToken.params ` + httpOptions.toString());

    return this.http.post<OauthToken>(GET_OAUTH_TOKEN_URI, params.toString(), httpOptions)
      .pipe(
        tap(_ => {
          console.log(`OAuth Token Successfully retrieved`);
        }),
        catchError(this.errorService.handleError('getOAuthToken', '')) // then handle the error
      );
  }

/*
  login(loginRequest: LoginRequest): Observable<any> {
    return this.request({
      url: `${environment.API_BASE_URL}/appuser/login`,
      method: 'POST',
      body: JSON.stringify(loginRequest)
    });
  }
*/


  login(loginRequest: LoginRequest): Observable<any> {
    const url = `${environment.API_BASE_URL}/auth/login`;
    console.log(url + ' ' + loginRequest.email + loginRequest.password + ' Second log');
    return this.http.post<LoginRequest>(url, loginRequest, httpOptions)
      .pipe(
        tap(_ => {
          console.log(`Login Request`);
        }),
        catchError(this.errorService.handleError('loginRequest', loginRequest)) // then handle the error
      );
  }
  private request(options: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (localStorage.getItem(environment.ACCESS_TOKEN)) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem(environment.ACCESS_TOKEN));
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return this.http.request(options.method, options.url, options)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
  }


  // App User Authorization
  getAppUsersValidation(userName: string, userPassword: string): Observable<any> {
    const url = VALIDATE_APPUSERS_URI + `/${userName}/${userPassword}`;
    return this.http.get<AppUser>(url)
      .pipe(
        tap(_ => {
          console.log(`User successfully Loggedin`);
        }),
        catchError(this.errorService.handleError('getAppUsersValidation', '')) // then handle the error
      );
  }

  getCurrentAppUser(argAppUserId: number): Observable<any> {
    const url = CURRENT_APPUSER_URI + `/${argAppUserId}`;
    return this.http.get<UserResponse>(url)
      .pipe(
        tap(_ => {
          console.log(`User successfully Loggedin`);
        }),
        catchError(this.errorService.handleError('getAppUsersValidation', '')) // then handle the error
      );
  }


  getCurrentUser(): Observable<any> {
    if (!localStorage.getItem(environment.ACCESS_TOKEN)) {
      return throwError(() => new Error('No access token set.'));
    }

    return this.request({
      url: `${environment.API_BASE_URL}/appuser/me`,
      method: 'GET'
    });
  }


  logout() {
    // remove user from local storage to log user out
    const message = new WebSocketMessage(this.LoggedInUser.personId,99999, // this will be the admin to view the loggin users
      this.LoggedInUser.firstName + ' ' + this.LoggedInUser.lastName, this.LoggedInUser.userProfile,
      'LOGGEDOUT', null, null , null);
    console.log('Send message to Admin as Logout');
    // close the socket
    // this.webSocketService.sendWSMsgToAdmin(message);
    this.webSocketService.disconnect();
    this.authGuardService.removeTokensAndResetFlags();
    this.router.navigate(['/site']);
  }


}
