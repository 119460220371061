<!--
  ~  ZAITEC CONFIDENTIAL
  ~  __________________
  ~
  ~ [2019] - [2020] zaiTEC Logixs Ltd
  ~ All Rights Reserved.
  ~
  ~ NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
  ~  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
  ~  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
  ~  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
  -->
<div style="text-align: center;">
  <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Call</h1>
</div>



<!--
<div>
  <button (click)="startCall()">Start Call</button>
  <button (click)="leaveCall()">Leave Call</button>

  <div id="local-player" style="width: 400px; height: 400px; border: 1px solid black; margin-bottom: 20px;">
    Local Stream
  </div>

  <div id="remote-players"></div> &lt;!&ndash; Container for remote players &ndash;&gt;
</div>
-->


<div [ngClass]="{'wrapper-class': sizeFlag}">
  <div class="remote-containers">
    <span center *ngIf="data.callType === 1 && remoteCalls.length > 0" style="text-align: center; display: block;">
      <b>
        <h3>Audio calling...</h3>
      </b>
    </span>
    <div class="remote">
      <div [id]="localCallId" class="local"></div>
      <div *ngIf="remoteCalls.length === 0 && data.fromTo === 'DOC-PAT'">
        <span center style="text-align: center; display: block;">
          <img src="../../../../assets/images/calling.gif" alt="" height="200vh" width="200vw">
          <b>
            <h3>Calling...</h3>
          </b>
        </span>

      </div>
      <div *ngFor="let callId of remoteCalls" [id]="callId" class="remote">
      </div>

    </div>
  </div>

</div>



<div mat-dialog-actions align="center">
  <button mat-fab [mat-dialog-close]="" (click)="onCallDisconnect()" cdkFocusInitial color="warn">
    <mat-icon matTooltip="Stop call">call_end</mat-icon>
  </button>
  <button mat-fab (click)="mute()" cdkFocusInitial>
    <mat-icon matSuffix>
      {{muteFlag ? 'mic_off' : 'mic'}}</mat-icon>
  </button>

  <button mat-fab (click)="sizeFlag = !sizeFlag" cdkFocusInitial color="primary">
    <mat-icon matSuffix>
      {{sizeFlag ? 'fullscreen_exit' : 'fullscreen'}}
    </mat-icon>
  </button>
</div>
