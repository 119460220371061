/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */


/*export const environment = {
  production: true,
  API_URL: 'https://pakapi.dochub.ie',
  CLIENT_ID_UNSERNAME: 'clientP@kN1ya247',
  CLIENT_SECRET_PASSWORD: 'secretP@kN1ya247',
  GRANT_TYPE: 'password',
  APPVERSION: require('../../package.json').version,
  APPNAME: require('../../package.json').name,
  agora: {
    appId: '3331e32398844fb69fd16321bb928f7b'
  }
};*/


/*export const environment = {
  production: true,
  API_URL: 'https://demoapi.dochub.ie',
  CLIENT_ID_UNSERNAME: 'clientP@kN1ya247',
  CLIENT_SECRET_PASSWORD: 'secretP@kN1ya247',
  GRANT_TYPE: 'password',
  APPVERSION: require('../../package.json').version,
  APPNAME: require('../../package.json').name,
  agora: {
    appId: '3331e32398844fb69fd16321bb928f7b'
  }
};*/

/*export const environment = {
  production: true,
  API_URL: 'https://api.dochub.ie',
  CLIENT_ID_UNSERNAME: 'clientP@kN1ya247',
  CLIENT_SECRET_PASSWORD: 'secretP@kN1ya247',
  GRANT_TYPE: 'password',
  APPVERSION: require('../../package.json').version,
  APPNAME: require('../../package.json').name,
  agora: {
    appId: '3331e32398844fb69fd16321bb928f7b'
  }
};*/



export const environment = {
  production: false,
  API_URL: 'https://qaapi.dochub.ie',
  /*  CLIENT_ID_UNSERNAME: 'client786',
    CLIENT_SECRET_PASSWORD: 'secret786',*/
  CLIENT_ID_UNSERNAME: 'clientP@kN1ya247',
  CLIENT_SECRET_PASSWORD: 'secretP@kN1ya247',
  GRANT_TYPE: 'password',
  APPVERSION: require('../../package.json').version,
  APPNAME: require('../../package.json').name,
  AGORA_APP_ID: '08c245ad2cb74c2fa2ab11d858fa53ac',
  agora: {
    // appId: '3331e32398844fb69fd16321bb928f7b'
    appId: '08c245ad2cb74c2fa2ab11d858fa53ac'
  },

  STRIPE_PUBLIC_KEY:'pk_test_51QI5SjHz6qOirsCHZjfd5OwJ2WQozwoN4qHcgrH3m55ag1MsCGCBNxuVQDuDBLMcXeKyTkvWzVL4iKRqvrCm5mUI00c4BxLeLF',
  API_BASE_URL :'https://qaapi.dochub.ie',
  OAUTH2_AUTHORIZE_URI : '/oauth2/authorize',
  UI_BASE_URL :'https://qa.dochub.ie/',

  ACCESS_TOKEN :'accessToken',
  ACCESS_TOKEN_HEADER_KEY :'Authorization',
  // separate redirect URIs are necessary to distinguish between the different OAuth2 providers and display on login

  GOOGLE_OAUTH2_REDIRECT_URI :'https://qaapi.dochub.ie/oauth2/google/redirect',
  GOOGLE_AUTH_URL :'https://qaapi.dochub.ie/oauth2/authorize/google?redirect_uri=https://qa.dochub.ie//site/oauth2/google/redirect',

  GITHUB_OAUTH2_REDIRECT_URI :'https://qaapi.dochub.ie/oauth2/github/redirect',
  GITHUB_AUTH_URL :'https://qaapi.dochub.ie/oauth2/authorizegithub?redirect_uri=https://qa.dochub.ie//oauth2/github/redirect',

};


/*export const environment = {
  production: true,
  API_URL: 'https://api.emedadvise.com',
  CLIENT_ID_UNSERNAME: 'clientP@kN1ya247',
  CLIENT_SECRET_PASSWORD: 'secretP@kN1ya247',
  GRANT_TYPE: 'password',
  APPVERSION: require('../../package.json').version,
  APPNAME: require('../../package.json').name,
  agora: {
    appId: '3331e32398844fb69fd16321bb928f7b'
  }
  };*/


/*export const environment = {
  production: true,
  /!* API_URL: 'https://vdocqaapi.TopDoc.ie',
  API_URL: 'https://pakapi.TopDoc.ie', *!/
  API_URL: 'https://demoapi.TopDoc.ie',
  CLIENT_ID_UNSERNAME: 'client786',
  CLIENT_SECRET_PASSWORD: 'secret786',
  GRANT_TYPE: 'password',
  APPVERSION: require('../../package.json').version,
  APPNAME: require('../../package.json').name,
  agora: {
    appId: '3331e32398844fb69fd16321bb928f7b'
  }
};*/

