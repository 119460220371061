/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */


export class CallDetails {
  public badgeNo: string;
  public callCancelledDt: Date;
  public callId : number ;
  //metadata
  public callStatus: string;
  //metadata but we need to get id from addressdetails.adress_id
  public callerAddressId : number ;
  public callerName: string;
  public callerPhoneNo: string;
  public createdBy : number ;
  public createdDate: Date;
  public docAcceptedDt: Date;
  public docArrivalDt: Date;
  public callReassignedDt: Date;
  //metadata
  public docArrivalEstMins: string;
  public docAssignedDt: Date;
  public docEstimatedDt: Date;
  public docId : number ;
  public docSubmitDt: Date;
  public externalPatientId : number ;
  public internalPatientId : number ;
  public lastModifiedBy : number ;
  public lastModifiedDate: Date;
  public notes: string;
  //metadata
  public offenceType: string;
  public patientDob: Date;
  public patientName: string;
  //multiselect metadata
  public presentingCompliants: string[]= new Array<string>();
  //metadata
  public presentingDemonor: string;

  public sendSms: string;
  public sendEmail: string;

  //metadata
  public specialRequest:string;

  //metadata RATE_CATEG 1.CATEG_1 2.CATEG_2 (Doctor rates: Categ1: Full rates, Categ2: Half rates )
  public rateCateg:string;

  //metadata CALL_LOG_BY  1.CALL_CENT  2.DOC_DBOARD
  public callLogBy:string;

}



