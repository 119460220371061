/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {required} from '@rxweb/reactive-form-validators';

export class SlotDtls {
  @required()
  public slotId: number;
  @required()
  public slotChosenBy: number;  // Pat. appUserId
  @required()
  public slotSelected: boolean;
  @required()
  public timeSlot: Date;
  @required()
  public timeSlotString: String;
  @required()
  slotTimingId: number;
}
