/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import { NgControl } from '@angular/forms';
import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[disableControl]'
})

export class DisableControlDirective implements OnChanges {

  @Input() disableControl: boolean = false;

  constructor(private ngControl: NgControl) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ngControl && this.ngControl.control) {
      const control = this.ngControl.control;
      if (this.disableControl) {
        control.disable(); // Disable the control
      } else {
        control.enable(); // Enable the control
      }
    }
  }
}
