import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";


@Injectable()
export class GlobalErrorHandingService {

  constructor(private http: HttpClient) {
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
     // console.log(`${operation} failed: ${error.message}`);
      let errorMessage = `${operation}` + ' failed: ' + `${error.message}`;

      if (error.error instanceof ErrorEvent) {
        // Client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // Server-side error
        errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      }

      // 200 error cods are returned for registration
      if(error.status === 404 || error.status === 200 ){
        console.log(errorMessage + 'Message: '+ error.error.message);
        // don't throw any exception
        return of(result as T);
      }else{
        console.error(errorMessage);
        return throwError(() => new Error(errorMessage));
      }
      //return throwError(() => new Error(errorMessage));
      // Let the app keep running by returning an empty result.
      //return of(result as T);
    };
  }
}
