/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppUser} from '../../shared';
import {MetaData} from '../model/metadata';
import {MetaDataService} from './metadata.service';
import {catchError, retry} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {GlobalErrorHandingService} from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';


const GET_CALLCENTER_ALL_URI: string = environment.API_URL + '/callcenter/all';
const GET_APPUSER_URL: string = environment.API_URL + '/appuser/appusers';

@Injectable()
export class DataService {
  public loggedIn;
  metaDataList: MetaData[];
  metaDataMap = new Map<'MetaData', MetaData[]>();
  metaDataListCast: Observable<Map<'MetaData', MetaData[]>>;
  // private metaDataListSubject: BehaviorSubject<MetaData[]>;
  private headers = new Headers({'Content-Type': 'application/json'});
// metaDataListSubject = new BehaviorSubject<Array<MetaData>>(this.metaDataList);
  private metaDataListSubject = new BehaviorSubject<Map<'MetaData', MetaData[]>>(this.metaDataMap);
  private testSubject = new BehaviorSubject<string>('Test');
  testCast = this.testSubject.asObservable();

  constructor(private http: HttpClient, private metaDataService: MetaDataService,
              private errorService: GlobalErrorHandingService) {
    this.metaDataListCast = this.metaDataListSubject.asObservable();
  }


  setMetaDataList() {
    this.metaDataService.getAllMetaDataList().subscribe(metaDataList => {
      this.metaDataList = metaDataList;
      console.log('metadata is DataServie ' + this.metaDataList);
      this.metaDataMap.set('MetaData', this.metaDataList);
      this.metaDataListSubject.next(this.metaDataMap);
      this.testSubject.next('Nawaz');
      console.log('metadata is DataServie ' + this.metaDataList);
      console.log('metadata is metaDataListSubject ' + this.metaDataListSubject);


      console.log('inside the list ' + this.metaDataList);
    });
  }


// App User Authorization
  getAppUsersValidation(userName: string, userPassword: string) {
    return this.http.get<AppUser>(GET_APPUSER_URL)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }

}
