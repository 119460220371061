import {DocHubStripePaymentObj} from "@/app/shared/model/DocHubStripePaymentObj";
import {GlobalErrorHandingService} from "@/app/shared/ErrorHandler/GlobalErrorHanding.service";
import {environment} from "@/environments/environment";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from "@angular/core";
import { Observable } from 'rxjs';

import {catchError} from "rxjs/operators";

const GET_PAYMENT_INTENT_URI: string = environment.API_URL + '/api/payment/create-payment-intent';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};
@Injectable()
export class DocHubStripePaymentService {

  constructor(private http: HttpClient, private errorService: GlobalErrorHandingService) {}

  createPaymentIntent(argsDocHubStripePaymentObj: DocHubStripePaymentObj): Observable<DocHubStripePaymentObj> {
    const url = GET_PAYMENT_INTENT_URI;
    return this.http.post<DocHubStripePaymentObj>(url, argsDocHubStripePaymentObj, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', new DocHubStripePaymentObj())) // then handle the error
      );
  }

}
